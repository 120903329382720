@import "@/assets/scss/style.scss";

.news-wrapper {
    width: fit-content;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    // border: 1px solid #d3d3ff;
    // border-radius: 4px;
    overflow: auto;
    padding: 0 3rem;
    width: 100%;
    min-width: 60%;
    max-height: 80vh;

    @media (max-width: 768px) {
        padding: 1rem;
        flex-wrap: wrap;
        flex-direction: row;
    }

    & > * {
        margin: 1rem 0;
        width: 100%;
    }

    .news__form-field {
        label {
            // font-weight: bold;
            // font-size: 1rem;
            // color: #000;
        }
        input {
            margin-top: 0.5rem;
        }

        .news-form__title {
            font-size: 1.4rem;
            color: rgb(28, 28, 28);
            font-weight: bold;
        }
    }

    .news__description-field {
        overflow-x: auto;
    }
    .news__attachment-container {
        min-width: 70%;
        margin-top: 2rem;
        align-self: center;
    }

    .news__main-button {
        align-self: center;
        // font-size: 1.2rem;
        width: fit-content;
    }
}
