@import "@/assets/scss/style.scss";

.ant-row {
    padding: 5px 0;
}
.dragger-wrapper {
}

.ant-upload-text {
    margin: 1rem !important;
}

.news-attachments__list {
    overflow-y: auto;
    max-height: 8rem;
}
